@import "../variables";
@import "../../bootstrap/mixins";

#our-word {
	.our-word-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding-top: 80px;
		padding-bottom: 112px;
		@include media-breakpoint-down(lg) {
			padding-bottom: 80px;
			gap: 16px;
		}

		.our-word-items {
			@include media-breakpoint-down(lg) {
				gap: 24px;
			}
		}

		.tagline-s {
			color: $gray05;
		}
	}
}

#live-graph {
	@include media-breakpoint-up(lg) {
		background: linear-gradient(to top, $blue-dark 50%, $white 50%);
	}

	.container {
		@include media-breakpoint-down(md) {
			padding-left: 0;
			padding-right: 0;
		}

	}

	.live-graph-container {
		background-color: $orange-off-white;
		display: flex;
		flex-direction: column;
		@include media-breakpoint-up(lg) {
			padding: 112px 116px;
			gap: 24px;
			border-radius: 48px;
			border: 1px solid $orange-pale;
		}
		@include media-breakpoint-down(lg) {
			gap: 16px;
			padding: 80px 20px;
		}
	}

	.cmp-counter__text {
		display: flex;
		padding-right: 0;
	}

	#cmp-counter--hours .cmp-counter__number {
		@include media-breakpoint-up(lg) {
			width: 105px;
		}
	}

	#cmp-counter--days .cmp-counter__number {
		@include media-breakpoint-up(lg) {
			width: 130px;
		}
	}
}

.h2 {
	margin-bottom: 16px;
	@include media-breakpoint-down(lg) {
		margin-bottom: 12px;
	}
}

.mod-en-us .gloweb-8096.cmp-counter__section::before {
	content: none;
}